.header {
  width: 100%;
  height: 80vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.post-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}

.post {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px;
  padding: 10px;
  width: calc(32% - 20px); /* Default for three posts per row */
  background-color: #f9f9f9;
  box-sizing: border-box;
}

@media (max-width: 1200px) {
  .header {
    padding-top: 100px;

  }
  .post {
    width: calc(48% - 20px); /* Two posts per row */
  }
}

@media (max-width: 768px) {
  .header {
    padding-top: 100px;

  }
  .post-page{
padding: 100px 20px 20px 20px;
text-align: center;
  }
  .post-container {
    
    flex-direction: column;
    align-items: center;
  }

  .post {
    width: 90%;
  }

  .post-content {
    flex-direction: column;
    align-items: center;
  }

  .post-page img {
    max-width: 100%;
    flex: none;
  }

  .post-text {
    min-width: auto;
  }
}

.post-image {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.post-title {
  font-size: 1.5em;
  margin: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
}

.post-brief-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  height: 3em; /* 2 lines x line-height */
}

.post-read-more {
  align-self: flex-start;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.post-read-more:hover {
  background-color: #0056b3;
}